import React from 'react'
import Comment from "./Comment"

const Indeed = props => {

    return(
        <section className="indeed-section">
            <div className="comments-carrousel">
                <Comment score={5}>
                    <Comment.Title>Great company!</Comment.Title>
                    <Comment.Description>Great company! Super quick recruiting process & training with experienced coaches. All went very smooth since day one of contacting edyn.care. Get my first placement about 2 weeks after interview. Great communication with office, no problems whatsoever. There is always someone there to help if You need it. BEST company which I've worked for so far! Pay every 2 weeks always on time! A+++++</Comment.Description>
                </Comment>
                <Comment score={5}>
                    <Comment.Title>edyn.care is a great company & care team to work.</Comment.Title>
                    <Comment.Description>I have worked with various companies before edyn.care. I can truly says edyn.care is the best care team to work. The management are exceptional supportive, calm, very professional, they listen to staff. The management will phone me every week to inquire about my wellbeing. I can not fault edyn.care. The management appreciate everything you do. Very happy working at edyn.care.</Comment.Description>
                </Comment>
                <Comment score={5}>
                    <Comment.Title>A great care company which also take care of its carers</Comment.Title>
                    <Comment.Description>It's always a pleasure to work for Edyn Care and the fantastic team. They are always listening for any help. I recommend this company to other carers. So far, for me, everything is fine.</Comment.Description>
                </Comment>
            </div>
        </section>
    )
}



export default Indeed