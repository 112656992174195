import React from "react";
import Helmet from "react-helmet";
import "../../scss/main.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link, useStaticQuery } from "gatsby";
import { Video } from "gatsby-video";
import IndeedStars from "../components/Indeed/IndeedStars";
import IndeedLogo from "../components/Indeed/IndeedLogo";
import BackgroundImage from "gatsby-background-image";
import Indeed from "../components/Indeed/Indeed";
import { StaticImage } from "gatsby-plugin-image";
import GreenCheckMark from "../components/icons/GreenCheckMark";
import Accordion from "../components/Accordion"
import LiveInCareChevron from "../components/icons/LiveInCare/LiveInCareChevron";
import EdynLeaves from "../components/icons/EdynLeaves";
import { PopupButton } from '@typeform/embed-react'

const BecomeACarer = () => {
    const { heroVideo, heroVideoBg } = useStaticQuery(graphql`
        query {
            heroVideo: file(relativePath: { eq: "becomeacarervideo.mov" }) {
                childVideoFfmpeg {
                    webm: transcode(
                        outputOptions: ["-crf 31", "-b:v 0"]
                        maxWidth: 900
                        maxHeight: 480
                        fileExtension: "webm"
                        codec: "libvpx-vp9"
                    ) {
                        width
                        src
                        presentationMaxWidth
                        presentationMaxHeight
                        originalName
                        height
                        fileExtension
                        aspectRatio
                    }
                    mp4: transcode(
                        maxWidth: 900
                        maxHeight: 480
                        fileExtension: "mp4"
                        codec: "libx264"
                    ) {
                        width
                        src
                        presentationMaxWidth
                        presentationMaxHeight
                        originalName
                        height
                        fileExtension
                        aspectRatio
                    }
                }
            }
            heroVideoBg: file(relativePath: { eq: "becomeacarer-hero.png" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const heroVideoFile = heroVideo.childVideoFfmpeg;
    const heroVideoBackground = heroVideoBg.childImageSharp.fluid;

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>Edyn Care | Become a Carer</title>
                <link href="css/main.css" rel="stylesheet" />
                <meta property="og:title" content="Edyn Care | Become a Carer"/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png"/>
				<meta property="og:url" content="https://www.edyn.care/become-a-carer" />
                <meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content="https://www.edyn.care/become-a-carer" />
				<meta name="twitter:title" content="Edyn Care | Become a Carer" />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
            </Helmet>
            <div className="container become-a-carer-page-v2">
                <Header />
                <section className="above-the-fold">
                    <div className="hero-video-container">
                        <BackgroundImage
                            className="video-bg"
                            fluid={heroVideoBackground}
                        >
                            <Video
								autoPlay
								loop
								playsInline
								sources={[heroVideoFile.webm, heroVideoFile.mp4]}
								muted
							/>
                        </BackgroundImage>
                        <div className="indeed">
                            <IndeedStars score={5} className="indeed-stars" />
                            <div className="rated-text">Rated excellent on</div>
                            <IndeedLogo className="logo" />
                        </div>
                    </div>
                    <div className="hero-text">
                        <h1>
                            A career that changes both yours and other lives
                        </h1>
                        <span className='typeform-popup-container'>
                            <PopupButton id="oEtQ7cos" className="btn-orange">
                                Apply today
                            </PopupButton>
                        </span>
                        {/* <Link to="https://www.app.edyn.care/join">
                            <button className="btn-orange">Apply today</button>
                        </Link> */}
                    </div>
                </section>
                <Indeed />
                <hr className="bac__divider" />
                <section className="bac-we-care">
                    <h1 className="bac-we-care__mobile-title">We care for our professional carers</h1>
                    <div className="bac-we-care__images">
                        <StaticImage src="../../static/assets/marketing-site/images/wecare-grid-1.png" placeholder="blurred"/>
                        <StaticImage src="../../static/assets/marketing-site/images/wecare-grid-2.png" placeholder="blurred"/>
                        <StaticImage src="../../static/assets/marketing-site/images/wecare-grid-3.png" placeholder="blurred"/>
                        <StaticImage src="../../static/assets/marketing-site/images/wecare-grid-4.png" placeholder="blurred"/>
                    </div>
                    <div className="bac-we-care__information">
                        <h1 className="information__title">We care for our professional carers</h1>
                        <p className="information__description">Our carers are the reason why we are so highly rated across England. That's why we treat our professional carers fairly and support them in developing their careers. We believe that better treated carers leads to better care.</p>
                        <div className="information__positive-points">
                            <div className="positive-points__point">
                                <GreenCheckMark />
                                <div>
                                    <h3>Competitive Pay</h3>
                                    <p>Our professional care pay starts at £740 per week, increasing depending on clients needs, so that pay reflects the package. We also have a £500 client referral scheme and a £50 carer referral scheme.</p>
                                </div>
                            </div>
                            <div className="positive-points__point">
                                <GreenCheckMark />
                                <div>
                                    <h3>The right client for you</h3>
                                    <p>You’ll be placed with clients based not only on your skills as a carer, but on your compatibility as people. We are out to build long lasting harmonious relationships. To do so, we look for shared interests, hobbies and backgrounds.</p>
                                </div>
                            </div>
                            <div className="positive-points__point">
                                <GreenCheckMark />
                                <div>
                                    <h3>Looking after your wellbeing</h3>
                                    <p>Wherever your care placement takes you, we’ll always be with you. You’ll have 24/7 access to our care management team as well as support from our industry leading care managers.</p>
                                </div>
                            </div>
                            <div className="positive-points__point">
                                <GreenCheckMark />
                                <div>
                                    <h3>Your professional development</h3>
                                    <p>Joining Edyn you’ll go through our comprehensive induction training and have the opportunity to complete your health and social care diplomas.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <hr className="bac__divider" />
                <section className="bac-values">
                    <h1 className="bac-values__title">Our carer values</h1>
                    <p className="bac_values__subtitle">Our philosophy to care sits at the centre of Edyn. Our carers are:</p>
                    <div className="bac-values__panels">
                        <div className="value_panel">
                            <StaticImage src="../../static/assets/marketing-site/images/value-1.png" placeholder="blurred" objectFit="contain"/>
                            <h4 className="value_panel__title">Attentive</h4>
                            <p className="value_panel__description">You make wise decisions, and identify root causes, in sometimes stressful situations. This requires an ability to use your common sense and think strategically.</p>
                        </div>
                        <div className="value_panel">
                            <StaticImage src="../../static/assets/marketing-site/images/value-2.png" placeholder="blurred" objectFit="contain"/>
                            <h4 className="value_panel__title">Passionate</h4>
                            <p className="value_panel__description">Willingness to work on something exciting to improve the care industry.</p>
                        </div>
                        <div className="value_panel">
                            <StaticImage src="../../static/assets/marketing-site/images/value-3.png" placeholder="blurred" objectFit="contain"/>
                            <h4 className="value_panel__title">Thoughtful</h4>
                            <p className="value_panel__description">You have great interpersonal skills and an understanding of others sensitivities. Going the extra mile.</p>
                        </div>
                        <div className="value_panel">
                            <StaticImage src="../../static/assets/marketing-site/images/value-4.png" placeholder="blurred" objectFit="contain"/>
                            <h4 className="value_panel__title">Honest</h4>
                            <p className="value_panel__description">You know your limitations and do not conduct work that you are unable to properly administer. When you make mistakes you are honest and transparent.</p>
                        </div>
                    </div>
                </section>
                <section className="bac-looking-for">
                    <div className="bac-looking-for__description">
                        <h1>What we are looking for</h1>
                        <p>
                        We are always looking for hard working, ambitious and smart carers looking to develop their careers who have the following:</p>
                    </div>
                    <div className="bac-looking-for__panel">
                        <div className="bac-looking-for__description--mobile">
                            <h1>What we are looking for</h1>
                            <p>
                            We are always looking for hard working, ambitious and smart carers looking to develop their careers who have the following:</p>
                        </div>
                        <div className="bac-looking-for-panel__point">
                            <GreenCheckMark />
                            <div className="panel_point_text">You align with our values</div>
                        </div>
                        <div className="bac-looking-for-panel__point">
                            <GreenCheckMark />
                            <div className="panel_point_text">Have the right to work in the UK</div>
                        </div>
                        <div className="bac-looking-for-panel__point">
                            <GreenCheckMark />
                            <div className="panel_point_text">1 year professional care experience</div>
                        </div>
                        <div className="bac-looking-for-panel__point">
                            <GreenCheckMark />
                            <div className="panel_point_text">Physically capable of assisting clients with mobility</div>
                        </div>
                        <div className="bac-looking-for-panel__point">
                            <GreenCheckMark />
                            <div className="panel_point_text">Have excellent spoken and written English</div>
                        </div>
                        <div className="bac-looking-for-panel__point">
                            <GreenCheckMark />
                            <div className="panel_point_text">In ownership of a smartphone</div>
                        </div>
                        <div className="bac-looking-for-panel__point">
                            <GreenCheckMark />
                            <div className="panel_point_text">Have up to date qualifications</div>
                        </div>
                        <div className="bac-looking-for-panel__point">
                            <GreenCheckMark />
                            <div className="panel_point_text">Willing to travel to clients</div>
                        </div>
                        <StaticImage src="../../static/assets/marketing-site/images/carer-1.png" placeholder="blurred" objectFit="contain" height={150}/>
                    </div>
                </section>
                <section className="bac-faq">
                    <h1 className="bac-faq__title">FAQs</h1>
                    <div className="bac-faq__questions">
                        <Accordion 
                            renderTitle={()=><h3>What will my responsibilities be on a day-to-day basis?</h3>}
                            renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
                            indentation={0}
                        >
                        <p>To ensure you have read the care recipient’s care plan, risk assessment and any other relevant documents to enable you to deliver safe care as appropriate to that care recipient. This may include:</p>
                        <ul>
                            <li>Personal care</li>
                            <li>Administering medication</li>
                            <li>Meal preparation and feeding</li>
                            <li>Assisting with the clients’ mobility, use of aids, hoists, transferring.</li>
                            <li>Assisting clients with sensory aids</li>
                            <li>Housework</li>
                            <li>Social engagement and shared interests/ hobbies</li>
                            <li>End of life care</li>
                            <li>Supporting with appointments</li>
                        </ul>
                        </Accordion>
                        <Accordion 
                            renderTitle={()=><h3>Do I have to do your in-house training?</h3>}
                            renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
                            indentation={0}
                        >
                            <p>Yes, all of our carers go through our comprehensive induction programme led by our recruitment team in our office in Battersea. We provide refreshments, lunch and all the tea and coffee you could ever desire. *In light of COVID-19 pandemic we will be conducting all of our carer training online through webinars and assessments.</p>
                        </Accordion>
                        <Accordion 
                            renderTitle={()=><h3>What are your pay rates?</h3>}
                            renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
                            indentation={0}
                        >
                            <p>We believe in treating our live-in carers fairly. Which is why we offer competitive pay starting at £740 per week, increasing depending on clients needs. We also have a £500 client referral scheme and a £50 carer referral scheme.</p>
                        </Accordion>
                        <Accordion 
                            renderTitle={()=><h3>What rotas do you have?</h3>}
                            renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
                            indentation={0}
                        >
                            <p>Our carers typically work 3 weeks on and 1 week off but for the right candidates we are flexible. We have rota’s which are 2 weeks on and 2 weeks off and 4 weeks on and 2 weeks off.</p>
                        </Accordion>
                        <Accordion 
                            renderTitle={()=><h3>Where will I be working?</h3>}
                            renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
                            indentation={0}
                        >
                            <p>Our carers work across England, so it is very important that you are willing to travel.</p>
                        </Accordion>
                        <Accordion 
                            renderTitle={()=><h3>Do you offer a food allowance?</h3>}
                            renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
                            indentation={0}
                        >
                            <p>Yes, if an agreement is not appropriate in matching foods for carer and client then a £40 per week food allowance will be charged and given to you weekly. The £40 allowance covers main food items like meat/ vegetarian substitutes. Other staple items like bread and milk will be supplied by the care recipient.</p>
                        </Accordion>
                        <Accordion 
                            renderTitle={()=><h3>Do you pay for travel?</h3>}
                            renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
                            indentation={0}
                        >
                            <p>No, our carers pay for their own travel.</p>
                        </Accordion>
                        <Accordion 
                            renderTitle={()=><h3>How soon can I start?</h3>}
                            renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
                            indentation={0}
                        >
                            <p>All our carers have to complete our induction training and get signed off by our recruitment team - completing necessary background checks and enhanced DBS checks.</p>
                        </Accordion>
                    </div>
                </section>
                <section className="bac-join-team">
                    <div className="bac-join-team__header">
                        <EdynLeaves className="bac-join-team__leaves-icon"/>
                        <h1 className="bac-join-team__header__title">How to join the team</h1>
                        <p className="bac-join-team__header__description">Here's how recruitement with Edyn works:</p>
                    </div>
                    <div className="bac-join-team__panels">
                        <div className="bac-join-team__panel">
                            <StaticImage className="bac-join-team__panel__bg" src="../../static/assets/marketing-site/images/bac-rectangle-1.png" objectFit="fill" placeholder="tracedSVG"/>

                            <StaticImage src="../../static/assets/marketing-site/images/bac-join-1.png" objectFit="contain" placeholder="blurred"/>
                            <div className="bac-join-team__panel__description">
                                <div className="bac-join-team__panel__description__number-wrapper">
                                    <div className="bac-join-team__panel__description__number">1</div>
                                </div>
                                <h3>Application and scenario testing</h3>
                                <p>If you are interested in joining our team please apply today and complete an online application after which we will send you some scenario tests which will assess how well you deal with certain situations and also your level of English.</p>
                                <span className='typeform-popup-container'>
                                    <PopupButton id="oEtQ7cos" className="btn-primary">
                                        Apply today
                                    </PopupButton>
                                </span>
                                {/* <Link to="https://www.app.edyn.care/join">
                                    <button className="btn-primary">Apply today</button>
                                </Link> */}
                            </div>
                        </div>
                        <div className="bac-join-team__panel">
                            <StaticImage className="bac-join-team__panel__bg" src="../../static/assets/marketing-site/images/bac-rectangle-2.png" objectFit="fill" placeholder="tracedSVG"/>
                            <StaticImage src="../../static/assets/marketing-site/images/bac-join-2.png" objectFit="contain" placeholder="blurred"/>
                            <div className="bac-join-team__panel__description">
                                <div className="bac-join-team__panel__description__number-wrapper">
                                    <div className="bac-join-team__panel__description__number">2</div>
                                </div>
                                <h3>Interviews</h3>
                                <p>After you have completed your scenario tests, if successful, we will invite you for a 1:1 interview with our recruitment and training specialist in which we review your previous experience, assess whether you would be a cultural fit and get a better understanding of your personal goals.</p>
                            </div>
                        </div>
                        <div className="bac-join-team__panel">
                            <StaticImage className="bac-join-team__panel__bg" src="../../static/assets/marketing-site/images/bac-rectangle-3.png" objectFit="fill" placeholder="tracedSVG"/>
                            <div className="bac-join-team__panel__points">
                                <div className="bac-join-team__panel__points-container">
                                    <div className="bac-join-team__panel__points_point">
                                        <GreenCheckMark />
                                        <div>Moving & handling</div>
                                    </div>
                                    <div className="bac-join-team__panel__points_point">
                                        <GreenCheckMark />
                                        <div>Medication</div>
                                    </div>
                                    <div className="bac-join-team__panel__points_point">
                                        <GreenCheckMark />
                                        <div>First aid</div>
                                    </div>
                                    <div className="bac-join-team__panel__points_point">
                                        <GreenCheckMark />
                                        <div>Challenging behaviour & Dementia</div>
                                    </div>
                                    <div className="bac-join-team__panel__points_point">
                                        <GreenCheckMark />
                                        <div>Safeguarding</div>
                                    </div>
                                    <div className="bac-join-team__panel__points_point">
                                        <GreenCheckMark />
                                        <div>Infection control</div>
                                    </div>
                                    <div className="bac-join-team__panel__points_point">
                                        <GreenCheckMark />
                                        <div>Further 10 modules</div>
                                    </div>
                                </div>
                                <StaticImage className="bac-join-team__panel__points_bg-texture" src="../../static/assets/marketing-site/images/bac-texture.png" objectFit="contain" placeholder="blurred"/>
                            </div>
                            <div className="bac-join-team__panel__description">
                                <div className="bac-join-team__panel__description__number-wrapper">
                                    <div className="bac-join-team__panel__description__number">3</div>
                                </div>
                                <h3>In-house Training</h3>
                                <p>The last stage is our training. The training at Edyn is second to none. All our carers are required to attend our induction training in London. We are also offering online training options during the pandemic.</p>
                            </div>
                        </div>
                    </div>
                </section> 
                <section className="bac-qa">
                    <h1 className="bac-qa__title">Development and Quality Assurance</h1>
                    <p className="bac-qa__subtitle">We support you in developing your career</p>
                    <div className="bac-qa__card-list">
                        <div className="bac-qa__card">
                            <div className="bac-qa__card-title--mobile">
                                <h3>Development</h3>
                            </div>
                            <StaticImage src="../../static/assets/marketing-site/images/bac-qa-1.png" placeholder="blurred" objectFit="contain"/>
                            <div className="bac-qa__card-description">
                                <div>
                                    <h3 className="bac-qa__card-description-title">Development</h3>
                                    <p className="bac-qa__card-description-text">We support all our carers in training in Health and Social Care to Diplomas as well as the opportunity for advanced training towards specialisation in a specific or complex condition. Our care management team completes informative webinars to keep our carers up-to-date on insights and developments in the industry.</p>
                                </div>
                                <ul className="bac-qa__card-description-points">
                                    <li>
                                        <div className="bac-qa__card-bullet">&#x2022;</div>
                                        <div>Funded Health and Social Care Diplomas</div>
                                    </li>
                                    <li>
                                        <div className="bac-qa__card-bullet">&#x2022;</div>
                                        <div>Online training</div>
                                    </li>
                                    <li>
                                        <div className="bac-qa__card-bullet">&#x2022;</div>
                                        <div>Webinars</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="bac-qa__card">
                            <div className="bac-qa__card-description">
                                <div>
                                    <h3 className="bac-qa__card-description-title">Quality Assurance</h3>
                                    <p className="bac-qa__card-description-text">Our Field Care Supervisors work with you to maintain the quality of our service. They are here to support you and your career development through 1:1 mentorship. All of them have been professional carers themselves, so understand the role. They help all of our professional carers through:</p>
                                </div>
                                <ul className="bac-qa__card-description-points">
                                    <li>
                                        <div className="bac-qa__card-bullet">&#x2022;</div>
                                        <div>Unannounced spot checks</div>
                                    </li>
                                    <li>
                                        <div className="bac-qa__card-bullet">&#x2022;</div>
                                        <div>Supervisions</div>
                                    </li>
                                    <li>
                                        <div className="bac-qa__card-bullet">&#x2022;</div>
                                        <div>Appraisals</div>
                                    </li>
                                </ul>
                            </div>
                            <div className="bac-qa__carer-card">
                                <div className="bac-qa__carer-profile">
                                    <StaticImage src="../../static/assets/marketing-site/images/team/angela.png" placeholder="blurred" objectFit="contain"/>
                                    <div>
                                        <p className="bac-qa__carer-profile-name">Angela Lowell</p>
                                        <p className="bac-qa__carer-profile-position">Field Care Supervisor</p>
                                    </div>
                                </div>
                                <p className="bac-qa__carer-quote">
                                    <div className="bac-qa__carer-quote-quotes">“</div>
                                    <div>
                                        I really enjoy getting to know our professional carers and care recipients and ensuring that our quality assurance processes are maintained. I spend a lot of the time in our client’s home completing quality assurance checks.
                                        <span className="bac-qa__carer-quote-quotes">”</span>
                                    </div>
                                </p>
                                <Link to="https://uk.indeed.com/cmp/Edyn.care/jobs" className="bac-qa__become-link">
                                    <button className="btn-primary">Become a Field Care Supervisor</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bac-cta">
                    <div className="bac-cta__card">
                        <StaticImage src="../../static/assets/marketing-site/images/we-care.png" placeholder="blurred" objectFit="contain" />
                        <div className="bac-cta__description">
                            <h1 className="bac-cta__description-title">Join the team today</h1>
                            <p className="bac-cta__description-text">
                                Are you looking for the next step in your career?
                                <br />
                                Fill out our application and if your successful, we'll be in touch.
                            </p>
                            <span className='typeform-popup-container'>
                                    <PopupButton id="oEtQ7cos" className="btn-orange">
                                        Apply here
                                    </PopupButton>
                                </span>
                            {/* <Link to="https://www.app.edyn.care/join">
                                <button className="btn-orange">Apply here</button>
                            </Link> */}
                        </div>
                    </div>
                </section>
                <Footer includeCTA={false} includeBecomeACarer={true} />
            </div>
        </>
    );
};

export default BecomeACarer;
