import React from 'react'
import Score1 from './Stars/Score1'
import Score2 from './Stars/Score2'
import Score3 from './Stars/Score3'
import Score4 from './Stars/Score4'
import Score5 from './Stars/Score5'

export default (props) => {
    const score = Math.round(props.score);
    switch(score){
        case 1:
            return <Score1 {...props} />
        case 2:
            return <Score2 {...props} />
        case 3:
            return <Score3 {...props} />
        case 4:
            return <Score4 {...props} />
        default:
            return <Score5 {...props} />

    }
}