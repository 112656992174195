import React from "react"
import IndeedStars from "./IndeedStars"



const Comment = (props) => {
    return(
        <div className="comment">
            <IndeedStars className="stars" score={props.score}/>
            {props.children}
            <div className="comment-author">{props.author}</div>
        </div>
    )
}

Comment.Title = ({children}) => <div className="comment-title">“{children}”</div>

Comment.Description = ({children}) => <div className="comment-description">{children}</div>


export default Comment